<template>
  <div class="mt-3">
    <v-row class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/verein/personen`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-file</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          PERSONEN IMPORTIEREN
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          class="rounded-xl pa-0"
          :style="`color: ${template.colors.block_text};`"
        >
          <v-row justify="center">
            <v-col cols="11" class="pb-0 pt-5" v-if="!csvfile.length > 0">
              <h3>Info:</h3>
              <p>
                <b>Baue deine CSV-Datei wie folgt auf:</b>
              </p>
              <code>
                Nachname;Vorname;Straße;PLZ;Ort;Mobil
                <br />
                Nachname1;Vorname1;Straße1;PLZ1;Ort1;Mobil1
                <br />
                Nachname2;Vorname2;Straße2;PLZ2;Ort2;Mobil2 usw
              </code>

              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5">
              <vue-csv-import
                v-model="csvfile"
                :map-fields="[
                  'Nachname',
                  'Vorname',
                  'Straße',
                  'PLZ',
                  'Ort',
                  'Mobil',
                ]"
                tableClass="table border"
                :headers="true"
                :autoMatchFields="true"
                v-if="!csvfile.length > 0"
              >
                <template slot="error">
                  Dateityp ist ungültig
                </template>

                <template slot="thead">
                  <p>
                    Weise die Spalten aus deiner Datei den benötigten Feldern zu
                  </p>
                  <tr>
                    <th style="border-bottom: 1px solid #000;">
                      Benötigte Felder
                    </th>
                    <th style="border-bottom: 1px solid #000;">
                      Spalten in deiner Datei
                    </th>
                  </tr>
                </template>

                <template slot="next" slot-scope="{ load }">
                  <v-btn
                    large
                    block
                    outlined
                    class="my-3"
                    :color="template.colors.primary"
                    @click.prevent="load"
                  >
                    DATEI LADEN
                  </v-btn>
                </template>

                <template slot="submit" slot-scope="{ submit }">
                  <v-btn
                    large
                    :color="template.colors.primary"
                    @click.prevent="submit"
                  >
                    IMPORT STARTEN
                  </v-btn>
                </template>
              </vue-csv-import>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5" v-if="csvfile.length > 0">
              <v-row>
                <v-col cols="12">
                  <h2>Import-Vorschau</h2>
                </v-col>
                <v-col cols="12">
                  <v-row class="font-weight-bold">
                    <v-col>Name</v-col>
                    <v-col>Adresse</v-col>
                    <v-col>Telefon</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-for="(row, index) in csvfile" :key="index">
                  <v-row>
                    <v-col>{{ row.Vorname }} {{ row.Nachname }}</v-col>
                    <v-col>{{ row.Straße }}, {{ row.PLZ }} {{ row.Ort }}</v-col>
                    <v-col>{{ row.Mobil }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="accept_privacy"
                    light
                    label="Ich bestätige, dass ich von den Inhabern der mit der CSV importierten Daten zur Speicherung der Daten autorisiert wurde und diesen die Datenschutzinformation zur Kenntnis gesetzt habe."
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pb-0">
              <v-card
                class="rounded-b-xl pa-4"
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :disabled="loading || csvfile.length == 0 || !accept_privacy"
                @click="startImport()"
              >
                <span class="font-weight-bold" v-if="!loading">
                  <v-icon class="mr-2">mdi-upload</v-icon>
                  TEAM IMPORTIEREN
                </span>
                <v-progress-circular
                  indeterminate
                  :color="template.colors.inline_primary_text"
                  v-if="loading"
                ></v-progress-circular>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'
import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'Personen CSV Import',
  data() {
    return {
      csvfile: [],
      loading: false,
      accept_privacy: false,
    }
  },
  components: { VueCsvImport },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      template: 'template',
    }),
  },
  methods: {
    async startImport() {
      if (this.csvfile) {
        this.loading = true
        var i
        for (i = 0; i < this.csvfile.length; i++) {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Personen')
            .add({
              vorname: this.csvfile[i].Vorname.trim(),
              name: this.csvfile[i].Nachname.trim(),
              adresse: this.csvfile[i].Straße.trim(),
              ort: this.csvfile[i].Ort.trim(),
              plz: this.csvfile[i].PLZ.trim(),
              telefon: this.csvfile[i].Mobil.toString().trim(),
              aktivitaet: 'Zuschauer',
            })
        }
        this.loading = false
        router.push('/verein/personen')
      }
    },
  },
}
</script>

<style scoped>
.border {
  border-bottom: 1px solid #000;
}
</style>
